<template>
  <div class="p-4 pt-10 md:pt-16">
        <div class="bg-white border border-innerBorder rounded-2xl p-4 lg:p-8 md:w-2/3 xl:w-1/3 mx-auto">
          <p class="font-bold text-2xl mb-6">Connexion</p>
          <LoginForm ref="loginForm"/>
        </div>
        <div class="my-4 px-2 font-bold text-sm sm:text-base text-center">
          <a @click.prevent="goTo()" class="text-blueLink cursor-pointer underline">Mot de passe oublié ?</a>
        </div>
    </div>
</template>

<script>
import LoginForm from '@/components/Form/LoginForm.vue'
export default {
  components: { LoginForm },
  methods: {
    goTo() {
      this.$refs.loginForm.setUsername()
      this.$router.push({
        path: '/reset-password'
      })
    }
  }
}
</script>

<style>

</style>